import { IconProps } from '@/components/icons/types';

export default function SvgOutdoorSpace(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M9.168 18.833V8h-7.5l8.333-5.833L18.335 8h-7.5v10.833zm.833-12.5h3.042H6.96zm-7.5 12.5v-4.375l-.666-3.667L3.46 10.5l.625 3.333H7.5v5H5.835V15.5H4.168v3.333zm10 0v-5h3.417l.625-3.333 1.625.291-.667 3.667v4.375h-1.666V15.5h-1.667v3.333zM6.96 6.333h6.083l-3.042-2.125z"
      />
    </svg>
  );
}
