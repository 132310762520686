import { IconProps } from '@/components/icons/types';

export default function SvgBarRestaurant(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 18v-1.667h4.167v-4.166L2.5 4.667V3h15v1.667l-6.667 7.5v4.166H15V18zM6.208 6.333h7.584l1.5-1.666H4.708zM10 10.583 12.313 8H7.687z"
      />
    </svg>
  );
}
