import { IconProps } from '@/components/icons/types';

export default function SvgLockers(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14.667 5.5c0 .16-.062.312-.171.424a.57.57 0 0 1-.413.176h-1.75a.57.57 0 0 1-.412-.176.61.61 0 0 1 0-.848.57.57 0 0 1 .412-.176h1.75c.155 0 .303.063.413.176s.17.265.17.424m-.584 1.8h-1.75a.57.57 0 0 0-.412.176.61.61 0 0 0 0 .848c.11.113.258.176.412.176h1.75a.57.57 0 0 0 .413-.176.61.61 0 0 0 0-.848.57.57 0 0 0-.413-.176M5.917 6.1h1.75a.57.57 0 0 0 .412-.176.61.61 0 0 0 0-.848.57.57 0 0 0-.412-.176h-1.75a.57.57 0 0 0-.413.176.61.61 0 0 0 0 .848c.11.113.258.176.413.176m1.75 1.2h-1.75a.57.57 0 0 0-.413.176.61.61 0 0 0 0 .848c.11.113.258.176.413.176h1.75a.57.57 0 0 0 .412-.176.61.61 0 0 0 0-.848.57.57 0 0 0-.412-.176M17 3.7v13.2a.6.6 0 0 1-.17.424.575.575 0 0 1-.826 0 .6.6 0 0 1-.17-.424v-1.2h-5.25v1.2c0 .16-.062.312-.171.424a.575.575 0 0 1-.825 0 .6.6 0 0 1-.171-.424v-1.2h-5.25v1.2a.6.6 0 0 1-.171.424.575.575 0 0 1-.825 0A.6.6 0 0 1 3 16.9V3.7c0-.318.123-.623.342-.849a1.15 1.15 0 0 1 .825-.351h11.666c.31 0 .606.126.825.351.22.226.342.53.342.849M9.417 14.5V3.7h-5.25v10.8zm1.166 0h5.25V3.7h-5.25z"
      />
    </svg>
  );
}
