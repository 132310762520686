import { IconProps } from '@/components/icons/types';

export default function SvgOnSiteParking(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5 18V3h5.833q2.085 0 3.542 1.458Q15.833 5.917 15.833 8q0 2.084-1.458 3.542T10.833 13h-2.5v5zm3.333-8.333H11q.687 0 1.177-.49T12.667 8t-.49-1.177A1.6 1.6 0 0 0 11 6.333H8.333z"
      />
    </svg>
  );
}
