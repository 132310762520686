import { IconProps } from '@/components/icons/types';

export default function SvgConferenceCenter(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M10.417 4.25q0 .687-.49 1.177t-1.177.49q-.27 0-.5-.073a1.6 1.6 0 0 1-.458-.24q-.5.167-.802.604a1.65 1.65 0 0 0-.303.959H17.5L16.667 13h-4.084v-1.666h2.646q.105-.626.177-1.25.073-.626.177-1.25H4.417q.104.624.177 1.25.072.624.177 1.25h2.646V13H3.333L2.5 7.167H5q0-1.02.563-1.854a3.3 3.3 0 0 1 1.52-1.23q.063-.646.542-1.072.48-.428 1.125-.428.687 0 1.177.49t.49 1.177M8.146 16.334h3.708l.48-5H7.666zM6.666 18l-.624-6.5a1.57 1.57 0 0 1 .416-1.281 1.6 1.6 0 0 1 1.23-.552h4.625q.729 0 1.229.552t.416 1.281l-.625 6.5z"
      />
    </svg>
  );
}
