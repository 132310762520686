import { IconProps } from '@/components/icons/types';

export default function SvgShowerFacilities(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M6.668 15.5a.8.8 0 0 1-.594-.24.8.8 0 0 1-.24-.593q0-.354.24-.594t.594-.24.594.24.24.594-.24.593a.8.8 0 0 1-.594.24m3.333 0a.8.8 0 0 1-.593-.24.8.8 0 0 1-.24-.593q0-.354.24-.594t.593-.24.594.24.24.594-.24.593a.8.8 0 0 1-.594.24m3.334 0a.8.8 0 0 1-.594-.24.8.8 0 0 1-.24-.593q0-.354.24-.594t.594-.24.593.24.24.594-.24.593a.8.8 0 0 1-.593.24m-9.167-3.333V10.5q0-2.208 1.417-3.833Q7 5.04 9.168 4.75V3h1.667v1.75q2.166.291 3.583 1.917 1.416 1.624 1.417 3.833v1.667zM5.835 10.5h8.333q0-1.729-1.219-2.948-1.218-1.218-2.948-1.219-1.728 0-2.948 1.22Q5.835 8.77 5.835 10.5m.833 7.5a.8.8 0 0 1-.594-.24.8.8 0 0 1-.24-.593q0-.354.24-.594t.594-.24.594.24.24.594-.24.593a.8.8 0 0 1-.594.24m3.333 0a.8.8 0 0 1-.593-.24.8.8 0 0 1-.24-.593q0-.354.24-.594t.593-.24.594.24.24.594-.24.593a.8.8 0 0 1-.594.24m3.334 0a.8.8 0 0 1-.594-.24.8.8 0 0 1-.24-.593q0-.354.24-.594t.594-.24.593.24.24.594-.24.593a.8.8 0 0 1-.593.24"
      />
    </svg>
  );
}
