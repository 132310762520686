import { IconProps } from '@/components/icons/types';

export default function SvgDiningOptions(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M5.832 18.833v-7.625a3.44 3.44 0 0 1-1.781-1.167A3.12 3.12 0 0 1 3.332 8V2.167h1.667V8h.833V2.167h1.667V8h.833V2.167h1.667V8a3.12 3.12 0 0 1-.72 2.041 3.44 3.44 0 0 1-1.78 1.167v7.625zm8.333 0v-6.666h-2.5V6.333q0-1.729 1.22-2.948 1.218-1.218 2.947-1.218v16.666z"
      />
    </svg>
  );
}
