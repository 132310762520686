import { IconProps } from '@/components/icons/types';

export default function SvgRetailOptions(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M4.999 18.833q-.688 0-1.177-.49a1.6 1.6 0 0 1-.49-1.177v-10q0-.687.49-1.177.489-.49 1.177-.49h1.666q0-1.374.98-2.353a3.2 3.2 0 0 1 2.354-.98 3.2 3.2 0 0 1 2.354.98 3.2 3.2 0 0 1 .979 2.354h1.667q.687 0 1.177.49.49.489.49 1.176v10q0 .688-.49 1.178t-1.177.49zm0-1.666h10v-10h-1.667v1.666q0 .354-.24.594a.8.8 0 0 1-.593.24.8.8 0 0 1-.594-.24.8.8 0 0 1-.24-.594V7.167H8.332v1.666q0 .354-.24.594a.8.8 0 0 1-.593.24.8.8 0 0 1-.594-.24.8.8 0 0 1-.24-.594V7.167H5zM8.332 5.5h3.333q0-.687-.49-1.177A1.6 1.6 0 0 0 10 3.833q-.688 0-1.177.49-.49.489-.49 1.177"
      />
    </svg>
  );
}
