import { IconProps } from '@/components/icons/types';

export default function SvgFitnessCenter(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M11.167 18.75 10 17.583l2.958-2.958-7.083-7.083L2.917 10.5 1.75 9.333l1.167-1.208L1.75 6.958l1.75-1.75L2.333 4 3.5 2.833 4.708 4l1.75-1.75 1.167 1.167L8.833 2.25 10 3.417 7.042 6.375l7.083 7.083 2.958-2.958 1.167 1.167-1.167 1.208 1.167 1.167-1.75 1.75L17.667 17 16.5 18.167 15.292 17l-1.75 1.75-1.167-1.167z"
      />
    </svg>
  );
}
