import { IconProps } from '@/components/icons/types';

export default function SvgDogFriendly(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M3.751 10.5a2.01 2.01 0 0 1-1.479-.604 2.01 2.01 0 0 1-.604-1.48q0-.875.604-1.479a2.01 2.01 0 0 1 1.48-.604q.875 0 1.478.604.605.604.605 1.48 0 .875-.605 1.479a2.01 2.01 0 0 1-1.479.604m3.75-3.333a2.01 2.01 0 0 1-1.479-.604 2.01 2.01 0 0 1-.604-1.48q0-.875.604-1.479A2.01 2.01 0 0 1 7.502 3q.875 0 1.478.604.605.604.605 1.48 0 .875-.605 1.479a2.01 2.01 0 0 1-1.479.604m5 0a2.01 2.01 0 0 1-1.479-.604 2.01 2.01 0 0 1-.604-1.48q0-.875.604-1.479A2.01 2.01 0 0 1 12.502 3q.875 0 1.478.604.605.604.605 1.48 0 .875-.605 1.479a2.01 2.01 0 0 1-1.479.604m3.75 3.333a2.01 2.01 0 0 1-1.479-.604 2.01 2.01 0 0 1-.604-1.48q0-.875.604-1.479a2.01 2.01 0 0 1 1.48-.604q.874 0 1.479.604.604.604.604 1.48 0 .875-.605 1.479a2.01 2.01 0 0 1-1.479.604M5.543 18.833q-.938 0-1.573-.718a2.48 2.48 0 0 1-.635-1.698q0-1.085.74-1.896.739-.813 1.468-1.604.604-.646 1.042-1.407a8.3 8.3 0 0 1 1.041-1.427q.459-.541 1.063-.896A2.55 2.55 0 0 1 10 8.834q.709 0 1.313.334.605.333 1.062.875a9 9 0 0 1 1.032 1.437q.447.77 1.052 1.438.729.79 1.468 1.604.74.812.74 1.896a2.48 2.48 0 0 1-.635 1.698q-.636.718-1.573.718-1.125 0-2.23-.187a13.3 13.3 0 0 0-4.458 0q-1.104.187-2.229.187"
      />
    </svg>
  );
}
