import { IconProps } from '@/components/icons/types';

export default function SvgConciergeServices(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.668 16.334v-1.667h16.667v1.667zm.833-2.5V13q0-2.667 1.636-4.708t4.198-2.583V5.5q0-.687.49-1.177.489-.49 1.176-.49.688 0 1.177.49t.49 1.177v.208q2.583.543 4.208 2.584T17.501 13v.834zm1.709-1.667h11.583q-.291-2.168-1.937-3.583Q12.208 7.167 10 7.167T6.158 8.584 4.21 12.167"
      />
    </svg>
  );
}
