import { IconProps } from '@/components/icons/types';

export default function SvgBikeStorage(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <g clipPath="url(#bike-storage_svg__a)">
        <path
          fill="currentColor"
          d="M4.167 17.167q-1.77 0-2.97-1.198Q0 14.77 0 13t1.219-2.969q1.218-1.197 2.948-1.197 1.604 0 2.698.958a4.2 4.2 0 0 1 1.385 2.375h.542L7.292 8H5.833V6.334H10V8h-.917l.292.834h4L12.167 5.5H10V3.834h2.167q.541 0 .968.291.428.292.615.792l1.417 3.875h.666q1.73 0 2.948 1.219 1.22 1.218 1.219 2.948 0 1.75-1.208 2.979-1.209 1.23-2.959 1.229-1.5 0-2.635-.938a4.15 4.15 0 0 1-1.448-2.395h-3.5a4.1 4.1 0 0 1-1.417 2.385q-1.125.948-2.666.948m0-1.667q.855 0 1.468-.469.615-.468.865-1.197H4.167v-1.667H6.5a2.43 2.43 0 0 0-.865-1.208Q5.02 10.5 4.167 10.5q-1.063 0-1.782.719-.718.72-.718 1.781 0 1.042.718 1.771.72.73 1.782.73m6.416-3.333h1.167q.105-.48.281-.896.178-.417.469-.77H9.958zm5.25 3.333q1.063 0 1.782-.729.718-.729.718-1.77 0-1.064-.718-1.782-.72-.72-1.782-.719h-.083l.833 2.209-1.583.583-.792-2.208a2.5 2.5 0 0 0-.646.833q-.228.48-.229 1.083 0 1.042.72 1.771.718.73 1.78.73"
        />
      </g>
      <defs>
        <clipPath id="bike-storage_svg__a">
          <path fill="#fff" d="M0 .5h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
