import { IconProps } from '@/components/icons/types';

export default function SvgCommunityEvents(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <g clipPath="url(#community-events_svg__a)">
        <path
          fill="currentColor"
          d="M.792 11.583a8 8 0 0 1-.584-1.52A6 6 0 0 1 0 8.5q0-2.334 1.583-3.917Q3.167 3 5.5 3a5.9 5.9 0 0 1 2.5.552 5.7 5.7 0 0 1 2 1.531 5.7 5.7 0 0 1 2-1.53A5.9 5.9 0 0 1 14.5 3q2.334 0 3.917 1.583Q20 6.167 20 8.5q0 .792-.208 1.563a8 8 0 0 1-.584 1.52 3.4 3.4 0 0 0-.541-.708 2.4 2.4 0 0 0-.73-.5 6.6 6.6 0 0 0 .292-.937 4.2 4.2 0 0 0 .104-.938q0-1.625-1.104-2.73-1.104-1.103-2.729-1.103-1.688 0-2.594.927A50 50 0 0 0 10 7.667a40 40 0 0 0-1.906-2.084q-.906-.916-2.594-.916-1.625 0-2.73 1.104Q1.668 6.875 1.668 8.5q0 .48.104.938.105.458.292.937-.417.188-.73.5a3.4 3.4 0 0 0-.541.708M0 18.833v-1.312q0-.916.927-1.469.927-.552 2.406-.552.27 0 .521.01.25.01.48.053-.292.416-.438.895T3.75 17.48v1.354zm5 0V17.48q0-1.355 1.385-2.187 1.386-.834 3.615-.834 2.25 0 3.625.834Q15 16.125 15 17.479v1.354zm11.25 0V17.48q0-.54-.135-1.02a3.3 3.3 0 0 0-.407-.896q.23-.042.47-.053.238-.01.489-.01 1.5 0 2.416.552.917.552.917 1.469v1.312zM10 16.125q-1.188 0-2.125.313-.937.311-1.104.729h6.479q-.187-.417-1.115-.73-.927-.312-2.135-.312m-6.667-1.458q-.687 0-1.177-.49A1.6 1.6 0 0 1 1.666 13q0-.708.49-1.187t1.177-.48q.709 0 1.188.48Q5 12.292 5 13q0 .687-.48 1.177-.478.49-1.187.49m13.334 0q-.688 0-1.177-.49A1.6 1.6 0 0 1 15 13q0-.708.49-1.187.489-.48 1.177-.48.708 0 1.187.48.48.479.48 1.187 0 .687-.48 1.177t-1.187.49M10 13.833a2.4 2.4 0 0 1-1.77-.729 2.4 2.4 0 0 1-.73-1.77q0-1.063.73-1.782A2.43 2.43 0 0 1 10 8.833q1.062 0 1.781.72.72.718.719 1.78 0 1.042-.719 1.771-.719.73-1.781.73m0-3.333a.8.8 0 0 0-.594.24.8.8 0 0 0-.24.593q0 .354.24.594t.594.24.594-.24.24-.594a.8.8 0 0 0-.24-.593.8.8 0 0 0-.594-.24"
        />
      </g>
      <defs>
        <clipPath id="community-events_svg__a">
          <path fill="#fff" d="M0 .5h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
