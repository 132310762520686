import { IconProps } from '@/components/icons/types';

export default function Svg247Security(props: IconProps) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.33464 18.8332C2.8763 18.8332 2.48394 18.67 2.15755 18.3436C1.83116 18.0172 1.66797 17.6248 1.66797 17.1665V7.99984C1.66797 7.5415 1.83116 7.14914 2.15755 6.82275C2.48394 6.49636 2.8763 6.33317 3.33464 6.33317H7.5013V3.83317C7.5013 3.37484 7.6645 2.98248 7.99089 2.65609C8.31727 2.3297 8.70963 2.1665 9.16797 2.1665H10.8346C11.293 2.1665 11.6853 2.3297 12.0117 2.65609C12.3381 2.98248 12.5013 3.37484 12.5013 3.83317V6.33317H16.668C17.1263 6.33317 17.5187 6.49636 17.8451 6.82275C18.1714 7.14914 18.3346 7.5415 18.3346 7.99984V17.1665C18.3346 17.6248 18.1714 18.0172 17.8451 18.3436C17.5187 18.67 17.1263 18.8332 16.668 18.8332H3.33464ZM3.33464 17.1665H16.668V7.99984H12.5013C12.5013 8.45817 12.3381 8.85053 12.0117 9.17692C11.6853 9.50331 11.293 9.6665 10.8346 9.6665H9.16797C8.70963 9.6665 8.31727 9.50331 7.99089 9.17692C7.6645 8.85053 7.5013 8.45817 7.5013 7.99984H3.33464V17.1665ZM5.0013 15.4998H10.0013V15.1248C10.0013 14.8887 9.93533 14.67 9.80338 14.4686C9.67144 14.2672 9.48741 14.1109 9.2513 13.9998C8.97352 13.8748 8.69227 13.7811 8.40755 13.7186C8.12283 13.6561 7.82075 13.6248 7.5013 13.6248C7.18186 13.6248 6.87977 13.6561 6.59505 13.7186C6.31033 13.7811 6.02908 13.8748 5.7513 13.9998C5.51519 14.1109 5.33116 14.2672 5.19922 14.4686C5.06727 14.67 5.0013 14.8887 5.0013 15.1248V15.4998ZM11.668 14.2498H15.0013V12.9998H11.668V14.2498ZM7.5013 12.9998C7.84852 12.9998 8.14366 12.8783 8.38672 12.6353C8.62977 12.3922 8.7513 12.0971 8.7513 11.7498C8.7513 11.4026 8.62977 11.1075 8.38672 10.8644C8.14366 10.6214 7.84852 10.4998 7.5013 10.4998C7.15408 10.4998 6.85894 10.6214 6.61589 10.8644C6.37283 11.1075 6.2513 11.4026 6.2513 11.7498C6.2513 12.0971 6.37283 12.3922 6.61589 12.6353C6.85894 12.8783 7.15408 12.9998 7.5013 12.9998ZM11.668 11.7498H15.0013V10.4998H11.668V11.7498ZM9.16797 7.99984H10.8346V3.83317H9.16797V7.99984Z"
        fill="currentColor"
      />
    </svg>
  );
}
