import { IconProps } from '@/components/icons/types';

export default function SvgRenovatedLobby(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M19.375 9.036H13.75a.57.57 0 0 0-.442.23 2 2 0 0 0-.183-.095V7.464c0-1.083-.7-1.964-1.563-1.964h-8.75c-.861 0-1.562.881-1.562 1.964v1.707C.523 9.495 0 10.368 0 11.393v4.321c0 .434.28.786.625.786H13.75c.345 0 .625-.352.625-.786v-2.75h1.562v1.964h-.625c-.345 0-.625.352-.625.786s.28.786.625.786h2.5c.346 0 .625-.352.625-.786s-.28-.786-.625-.786h-.625v-1.964h2.188c.345 0 .625-.351.625-.786V9.822c0-.434-.28-.785-.625-.785M2.813 7.07h8.75c.172 0 .312.176.312.393v1.707c-.727.324-1.25 1.198-1.25 2.222v.393H3.75v-.393c0-1.025-.523-1.898-1.25-2.222V7.464c0-.217.14-.393.313-.393M1.25 11.393c0-.433.28-.786.625-.786s.625.353.625.786v3.535H1.25zm2.5 1.964h6.875v1.572H3.75zm9.375 1.572h-1.25v-3.536c0-.433.28-.786.625-.786s.625.353.625.786zm5.625-3.536h-4.375v-.786h4.375z"
      />
    </svg>
  );
}
