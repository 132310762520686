import { IconProps } from '@/components/icons/types';

export default function SvgFallback(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="m7.957 15.5-4.75-4.75 1.188-1.188 3.562 3.563 7.646-7.646 1.187 1.188z"
      />
    </svg>
  );
}
