import { ReactNode } from 'react';
import clsx from 'clsx';


import Security247Icon from '@/components/icons/247Security';
import BarRestaurantIcon from '@/components/icons/BarRestaurant';
import BikeStorageIcon from '@/components/icons/BikeStorage';
import CommunityEventsIcon from '@/components/icons/CommunityEvents';
import ConciergeServicesIcon from '@/components/icons/ConciergeServices';
import ConferenceCenterIcon from '@/components/icons/ConferenceCenter';
import DiningOptionsIcon from '@/components/icons/DiningOptions';
import DogFriendlyIcon from '@/components/icons/DogFriendly';
import EventSDpaceIcon from '@/components/icons/EventSpace';
import FallbackIcon from '@/components/icons/Fallback';
import FitnessCenterIcon from '@/components/icons/FitnessCenter';
import LockersIcon from '@/components/icons/Lockers';
import OnSiteParkingIcon from '@/components/icons/OnSiteParking';
import OutdoorSpaceIcon from '@/components/icons/OutdoorSpace';
import RenovatedLobbyIcon from '@/components/icons/RenovatedLobby';
import RetailOptionsIcon from '@/components/icons/RetailOptions';
import ShowerFacilitiesIcon from '@/components/icons/ShowerFacilities';


export enum AmenityCode {
  Security247 = '247-security',
  BarRestaurant = 'bar-restaurant',
  BikeStorage = 'bike-storage',
  CommunityEvents = 'community-events',
  ConciergeServices = 'concierge-services',
  ConferenceCenter = 'conference-center',
  DiningOptions = 'dining-options',
  DogFriendly = 'dog-friendly',
  EventSDpace = 'event-space',
  FitnessCenter = 'fitness-center',
  Lockers = 'lockers',
  OnSiteParking = 'on-site-parking',
  OutdoorSpace = 'outdoor-space',
  RenovatedLobby = 'renovated-lobby',
  RetailOptions = 'retail-options',
  ShowerFacilities = 'shower-facilities',
}

export const AmenityIcons = {
  [AmenityCode.Security247]: Security247Icon,
  [AmenityCode.BarRestaurant]: BarRestaurantIcon,
  [AmenityCode.BikeStorage]: BikeStorageIcon,
  [AmenityCode.CommunityEvents]: CommunityEventsIcon,
  [AmenityCode.ConciergeServices]: ConciergeServicesIcon,
  [AmenityCode.ConferenceCenter]: ConferenceCenterIcon,
  [AmenityCode.DiningOptions]: DiningOptionsIcon,
  [AmenityCode.DogFriendly]: DogFriendlyIcon,
  [AmenityCode.EventSDpace]: EventSDpaceIcon,
  [AmenityCode.FitnessCenter]: FitnessCenterIcon,
  [AmenityCode.Lockers]: LockersIcon,
  [AmenityCode.OnSiteParking]: OnSiteParkingIcon,
  [AmenityCode.OutdoorSpace]: OutdoorSpaceIcon,
  [AmenityCode.RenovatedLobby]: RenovatedLobbyIcon,
  [AmenityCode.RetailOptions]: RetailOptionsIcon,
  [AmenityCode.ShowerFacilities]: ShowerFacilitiesIcon,
};

export type AmenityProps = {
  code: AmenityCode;
  className?: string;
  name?: string;
  children?: ReactNode;
  iconClassName?: string;
  labelClassName?: string;
};

export function Amenity({
  code,
  className,
  name,
  children,
  iconClassName,
  labelClassName,
}: AmenityProps) {
  const IconComponent = AmenityIcons[code] || FallbackIcon;

  return (
    <div className={clsx(className, 'flex items-center')}>
      <IconComponent aria-hidden="true" className={iconClassName} />
      <span className={clsx(labelClassName, 'ml-1 truncate')}>{name}</span>
      {children}
    </div>
  );
}
