import { IconProps } from '@/components/icons/types';

export default function SvgEventSpace(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={21}
      fill="none"
      {...props}
    >
      <path
        fill="currentColor"
        d="M1.668 18.833 5.835 7.167l7.5 7.5zm2.75-2.75L10.293 14l-3.792-3.792zm7.708-5.125-.875-.875 4.667-4.666q.667-.667 1.604-.667t1.604.667l.5.5-.875.875-.5-.5A1 1 0 0 0 17.522 6a1 1 0 0 0-.729.292zM8.793 7.625l-.875-.875.5-.5a.96.96 0 0 0 .292-.708.96.96 0 0 0-.292-.709l-.542-.541.875-.875.542.541q.667.668.667 1.584t-.667 1.583zm1.667 1.667-.875-.875 3-3a1 1 0 0 0 .291-.73 1 1 0 0 0-.291-.729L11.25 2.625l.875-.875 1.334 1.333q.666.668.666 1.604 0 .938-.666 1.605zm3.333 3.333-.875-.875 1.333-1.333q.668-.667 1.604-.667.938 0 1.605.667l1.333 1.333-.875.875-1.333-1.333a1 1 0 0 0-.73-.292 1 1 0 0 0-.729.292z"
      />
    </svg>
  );
}
